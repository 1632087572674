<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Help Search</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="12">
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <v-text-field
                  v-model="helpSearch.company_name"
                  type="text"
                  label="Company name"
                  :rules="[rule.required]"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.phone"
                  type="text"
                  label="Phone"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.address"
                  type="text"
                  label="Address"
                  :rules="[rule.required]"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.zipcode"
                  @keyup="onZipcodeChanged"
                  type="text"
                  label="Zip code"
                  :rules="[rule.required, rule.zipcode]"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.gps_lat"
                  type="text"
                  label="Latitude"
                  :rules="[ decimalRule ]"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.gps_long"
                  type="number"
                  label="Longitude"
                  :rules="[ decimalRule ]"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.city"
                  type="text"
                  label="City"
                  :rules="[rule.required]"
                  outlined
                />
                <v-combobox
                  v-model="helpSearch.addictions"
                  :items="addictions"
                  label="Addictions"
                  :return-object="true"
                  :item-text="o => o.name"
                  multiple
                  outlined
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      @click:close="data.parent.selectItem(data.item)"
                      close-icon="mdi-close"
                      close
                    >
                      {{ data.item.name ? data.item.name : data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
                <v-combobox
                  v-model="helpSearch.treatment_types"
                  :items="treatmentTypes"
                  label="Treatment types"
                  :return-object="true"
                  :item-text="o => o.name"
                  multiple
                  outlined
                  chips
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      @click:close="data.parent.selectItem(data.item)"
                      close-icon="mdi-close"
                      close
                    >
                      {{ data.item.name ? data.item.name : data.item }}
                    </v-chip>
                  </template>
                </v-combobox>

                <v-text-field
                  v-model="helpSearch.compensated"
                  type="text"
                  label="Compensation"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.own_risk"
                  type="text"
                  label="Own risk"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.own_contribution"
                  type="text"
                  label="Own contribution"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.website"
                  type="text"
                  label="Website"
                  outlined
                />
                <v-text-field
                  v-model="helpSearch.voor_naasten"
                  type="text"
                  label="Voor naasten"
                  outlined
                />
                <v-checkbox v-model="helpSearch.mdft" label="MDFT" outlined />
                <v-checkbox
                  v-model="helpSearch.coggedth"
                  label="CogGedTh"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.mgv"
                  label="MGV (Motiverende gespreksvoering)"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.systeemtherapie"
                  label="Systeemtherapie"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.irb"
                  label="IRB (Individuele Rehabilitatie Benadering)"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.twaalf_stappen"
                  label="12 stappen (Minnesota)"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.schematherapie"
                  label="Schematherapie"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.psycho_educatie"
                  label="Psycho-educatie"
                  outlined
                />
                <v-checkbox v-model="helpSearch.act" label="ACT" outlined />
                <v-checkbox
                  v-model="helpSearch.cra"
                  label="CRA (ComntyReinfAppr)"
                  outlined
                />
                <v-checkbox v-model="helpSearch.emdr" label="EMDR" outlined />
                <v-checkbox
                  v-model="helpSearch.psychotherapie"
                  label="Psychotherapie"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.mindfulness"
                  label="Mindfulness"
                  outlined
                />
                <v-checkbox
                  v-model="helpSearch.terugvalpreventie"
                  label="Terugvalpreventie"
                  outlined
                />
                <v-textarea
                  v-model="helpSearch.comment"
                  label="Comment"
                  outlined
                />
                <v-btn
                  color="primary"
                  class="float-right"
                  :loading="getHelpSearchLoading"
                  @click="save"
                >
                  {{ isCreate ? `Create help search` : "Save changes" }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";
import { getGpsDataByZipcode } from "@/services/helpSearchService";
import _ from "lodash";
import store from "@/store";
import { getRelationshipData } from "@/services/helpSearchService";

export default {
  metaInfo: {
    title: "Help search"
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      helpSearch: {
        company_name: null,
        address: null,
        zipcode: null,
        city: null,
        compensated: "",
        own_risk: "",
        own_contribution: "",
        website: "",
        voor_naasten: "",
        mdft: false,
        coggedth: false,
        mgv: false,
        systeemtherapie: false,
        irb: false,
        twaalf_stappen: false,
        schematherapie: false,
        psycho_educatie: false,
        act: false,
        cra: false,
        emdr: false,
        psychotherapie: false,
        mindfulness: false,
        terugvalpreventie: false,
        comment: null,
        addictions: [],
        treatment_types: []
      },
      addictions: [],
      treatmentTypes: [],
      el: {
        validForm: false,
        relationshipsLoading: false
      }
    };
  },
  async mounted() {
    try {
      this.relationshipsLoading = true;
      const { data } = await getRelationshipData();
      this.addictions = _.get(data, "addictions");
      this.treatmentTypes = _.get(data, "treatmentTypes");
    } catch (error) {
      console.error("DEBUG: [getRelationshipData]", error);
    }

    this.relationshipsLoading = false;

    if (!this.isCreate) {
      this.helpSearch = _.cloneDeep(store.getters.getHelpSearch(this.id));
    }
  },
  methods: {
    ...mapActions(["createHelpSearch", "updateHelpSearch"]),
    async onZipcodeChanged() {
      this.helpSearch.zipcode = _.toUpper(_.trim(this.helpSearch.zipcode));
      if (rule.zipcode(this.helpSearch.zipcode) !== "Invalid") {
        try {
          const { data } = await getGpsDataByZipcode(this.helpSearch.zipcode);
          this.helpSearch.gps_lat = _.get(data, 'gps_lat');
          this.helpSearch.gps_long = _.get(data, 'gps_long');
        } catch (error) {
          console.error('DEBUG: getGpsDataByZipcode', error);
        }
      }
    },
    decimalRule(value) {
      return /^-?\d+(\.\d+)?$/.test(value) || "Invalid";
    },
    save() {
      if (!this.$refs.form.validate()) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = {
        ...this.helpSearch,
        addictions: _.map(this.helpSearch.addictions, v => _.get(v, "id", v)),
        treatment_types: _.map(this.helpSearch.treatment_types, v =>
          _.get(v, "id", v)
        )
      };

      const errors = this.isCreate
        ? this.createHelpSearch(payload)
        : this.updateHelpSearch({ id: this.id, payload });
    }
  },
  computed: {
    ...mapGetters(["getHelpSearchLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    }
  }
};
</script>
